<template>
  <div class="section-zupus">
    <div class="heading-5">Discover records from a Zupu (Family Tree Book)</div>
    <img
      class="image lazyload"
      width="800"
      height="220"
      :data-src="$getAsset('/assets/services/zupus/zupu-preview-optimized.png')"
    />
    <div class="bold">Family Tree Records (Zupus)</div>
    <p>
      Zupus are private documents compiled by families to record the names and details of ancestors. They can include
      several hundreds of years of family history, including: dates, names, accomplishments, but also surname origins,
      and migration histories.
    </p>
    <router-link :to="zupuSearchRoute"
      >Search {{ count.toLocaleString('en') }} {{ pinyin }} family tree books</router-link
    >
  </div>
</template>

<script>
import {TAB_ID_SOURCES} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';

export default {
  props: {
    pinyin: String,
    count: Number,
  },
  computed: {
    zupuSearchRoute() {
      const query = {
        category_id: sourceTypeCategories.CATEGORY_ZUPUS,
        tab: TAB_ID_SOURCES,
        auto_place_id: this.$route.params.placeId,
      };
      return {name: 'search-all-records', query};
    },
  },
  name: 'SectionZupus',
};
</script>

<style scoped lang="scss">
.section-zupus {
  .image {
    object-fit: cover;
    min-height: 200px;
    display: block;
    margin-top: 18px;
    box-shadow: $box-shadow-light;
    border-radius: 4px;
  }
  .bold {
    margin-top: 16px;
  }
}
</style>

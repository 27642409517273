<template>
  <div class="readable_content loading" v-if="loading || !renderComponent" ref="loaderContainer">
    <ContentLoader
      viewBox="0 0 800 2700"
      primaryColor="'DBD8D4"
      secondaryColor="#CCC"
      :width="loaderWidth"
      :height="loaderHeight"
    >
      <!-- Main Title Block -->
      <rect x="0" y="50" rx="4" ry="4" width="100%" height="57" />

      <!-- Description Block -->
      <rect x="0" y="127" rx="4" ry="4" width="100%" height="300" />

      <!-- CTA Block 1 -->
      <rect x="0" y="470" rx="4" ry="4" width="100%" height="160" />

      <!-- CTA Block 2 -->
      <rect x="0" y="680" rx="4" ry="4" width="100%" height="116" />

      <!-- Map Block -->
      <rect x="0" y="846" rx="4" ry="4" width="100%" height="500" />

      <!-- 4 Image Blocks (2 by 2 square grid) -->
      <!-- First Row -->
      <rect x="0" y="1396" rx="4" ry="4" width="50%" height="250" />
      <rect x="52%" y="1396" rx="4" ry="4" width="50%" height="250" />
      <!-- Second Row -->
      <rect x="0" y="1656" rx="4" ry="4" width="50%" height="250" />
      <rect x="52%" y="1656" rx="4" ry="4" width="50%" height="250" />

      <!-- Information Block 1 -->
      <rect x="0" y="1956" rx="4" ry="4" width="100%" height="400" />

      <!-- Information Block 2 -->
      <rect x="0" y="2406" rx="4" ry="4" width="100%" height="400" />
    </ContentLoader>
  </div>
  <component v-else-if="renderComponent" :is="renderComponent" :search-records-meta="searchRecordsMeta"></component>
</template>

<script>
import ContentLoader from 'vue-content-loading';
import {mapGetters} from 'vuex';

import MigrationDestinationDetailsPage from './migrationDestinationDetails/MigrationDestinationDetailsPage';
import VillageDetailsPage from './villageDetails/VillageDetailsPage';

const PageNotFound = () => import('@/components/page.not.found');

export default {
  created() {
    this.init();
  },
  mounted() {
    this.updateLoaderDimensions();
    window.addEventListener('resize', this.updateLoaderDimensions);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateLoaderDimensions);
  },
  watch: {
    '$route.params': {
      handler: function (toParams, fromParams) {
        if (toParams.placeId !== fromParams.placeId) {
          this.init();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loaded: false,
      loaderWidth: 0,
      loaderHeight: 0,
      searchRecordsMeta: {},
    };
  },
  computed: {
    ...mapGetters(['placeState', 'placeLoadingState']),
    loading() {
      return !this.loaded;
    },
    renderComponent() {
      if (!this.placeState || !this.placeState.id) {
        return PageNotFound;
      }
      if (this.placeState.is_ancestral_place) {
        return VillageDetailsPage;
      }
      return MigrationDestinationDetailsPage;
    },
  },
  methods: {
    init() {
      this.$scrollTo({x: 0, y: 0});

      this.$store.commit('setPlaceState', {});
      this.loaded = false;

      const placeDataPromise = this.$store.dispatch('fetchPlaceDetailsAction', this.$route.params.placeId);
      const searchMentionsPromise = this.$store.dispatch('searchAllRecordsSilentAction', {
        auto_place_id: this.$route.params.placeId,
        only_meta: true,
      });
      const searchSourcesPromise = this.$store.dispatch('searchSourcesInBackgroundAction', {
        auto_place_id: this.$route.params.placeId,
        only_meta: true,
      });
      Promise.all([searchMentionsPromise, searchSourcesPromise, placeDataPromise])
        .then(responses => {
          this.searchRecordsMeta = {...responses[0].meta, sourcesMeta: responses[1].meta};
        })
        .finally(() => {
          this.loaded = true;
        });
      if (!this.$store.getters.searchCategoriesListState.length) {
        this.$store.dispatch('fetchSearchCategoriesListAction');
      }
    },
    updateLoaderDimensions() {
      const container = this.$refs.loaderContainer;
      if (container) {
        this.loaderWidth = container.offsetWidth;
        this.loaderHeight = container.offsetHeight + 100;
      }
    },
  },
  components: {MigrationDestinationDetailsPage, VillageDetailsPage, PageNotFound, ContentLoader},
  name: 'PlaceDetailsPage',
};
</script>

<style lang="scss" scoped>
.readable_content.loading {
  max-width: 900px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
</style>

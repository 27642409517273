<template>
  <div class="search-overseas-page">
    <div class="limited_content">
      <h4>Overseas Chinese Communities</h4>
      <div class="search-content-wrapper">
        <div class="search-controls">
          <search-overseas-form ref="form" @submit="onSubmit"></search-overseas-form>
          <mcr-button @click="onSubmit">Search</mcr-button>
        </div>
        <div class="search-content search-results-content">
          <mcr-loading-indicator v-if="placeOverseasSearchLoadingState" :loading="true"></mcr-loading-indicator>
          <search-error v-else-if="searchError" :text="searchErrorText"></search-error>
          <div class="search-results-container" v-else-if="showResults">
            <div v-if="placeOverseasSearchMetaState.total_count === 0" class="results-empty">
              <h5>No match found.</h5>
            </div>
            <base-pagination-meta :meta="placeOverseasSearchMetaState"></base-pagination-meta>
            <div class="search-results">
              <search-overseas-result-item
                v-for="item in placeOverseasSearchState"
                :key="item.id"
                :item="item"
              ></search-overseas-result-item>
            </div>
            <base-pagination
              v-if="showPagination"
              :meta="placeOverseasSearchMetaState"
              @onSwitchPage="onSwitchPage"
            ></base-pagination>
          </div>
          <div class="search-results-container search-tips">
            <div class="getting-started" v-if="!showResults && !searchError">
              <div class="bold">Explore Communities across the Chinese Diaspora</div>
              <p>
                Get to know what's special about the places your Chinese ancestors migrated to, including their unique
                immigration history, most popular Chinese surnames, and records available from their region of the
                diaspora.
              </p>
              <p class="meta-info-item">
                <b>Popular communities: </b>
                <router-link class="name" v-for="place in popularCommunities" :key="place.id" :to="place.route">{{
                  place.label
                }}</router-link>
              </p>
            </div>
            <div>
              Want to search villages and other places in China? Try the
              <router-link :to="{name: 'search-tool-village'}">Chinese Villages Search</router-link>
            </div>

            <data-map
              v-if="migrationRegionsData"
              :data="migrationRegionsData"
              :get-text-display="mapGetTextDisplay"
            ></data-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePaginationMeta from '@common/elements/base-pagination-meta.vue';
import BasePagination from '@common/elements/base-pagination.vue';
import McrButton from '@common/elements/buttons/mcrButton.vue';
import SearchOverseasForm from '@common/pages/searchPlacesOverseas/SearchOverseasForm.vue';
import SearchOverseasResultItem from '@common/pages/searchPlacesOverseas/SearchOverseasResultItem.vue';
import SearchError from '@common/pages/searches/results/SearchError.vue';
import consts from '@common/utils/consts';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

const DataMap = () => import('@common/elements/maps/DataMap');

export default {
  metaInfo: {
    title: 'Overseas Chinese Communities',
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      showResults: false,
      searchError: false,
      searchErrorText: '',
      migrationRegionsData: null,
    };
  },
  computed: {
    ...mapGetters([
      'placeOverseasSearchState',
      'placeOverseasSearchMetaState',
      'placeOverseasSearchQueryState',
      'placeOverseasSearchLoadingState',
    ]),
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.tablet;
    },
    scrollToElement() {
      return this.isDesktop ? '.search-overseas-page' : '.search-results-content';
    },
    scrollOffset() {
      return this.isDesktop ? -consts.MAIN_MENU_HEIGHT : -consts.MAIN_MENU_HEIGHT_MOBILE;
    },
    filters() {
      return {q: this.placeOverseasSearchQueryState};
    },
    showPagination() {
      return this.placeOverseasSearchMetaState.next || this.placeOverseasSearchMetaState.previous;
    },
    popularCommunities() {
      return [
        this.getPopularCommunityCountry(651533, 'United States'),
        this.getPopularCommunityCountry(651535, 'Canada'),
        this.getPopularCommunityCountry(651532, 'Australia'),
        this.getPopularCommunityCountry(651528, 'Singapore'),
        this.getPopularCommunityCountry(651539, 'Jamaica'),
        this.getPopularCommunityCountry(651527, 'Malaysia'),
        this.getPopularCommunityCountry(651529, 'Indonesia'),
        this.getPopularCommunityCountry(651545, 'Peru'),
      ];
    },
  },
  methods: {
    init() {
      this.parseFiltersFromQuery();
      if (!this.filters.q) {
        this.network.place.migrationRegions().then(response => {
          this.migrationRegionsData = response;
        });
        return;
      }
      this.searchPlaces(this.$route.query.page);
    },
    parseFiltersFromQuery() {
      this.$refs.form.setFormData(this.$route.query);
      this.$store.commit('setPlaceOverseasSearchQueryState', this.$route.query.q || '');
    },
    onSubmit() {
      const filters = this.$refs.form.getData();
      if (!filters.q) {
        return;
      }
      this.$store.commit('setPlaceOverseasSearchQueryState', filters.q);
      this.$router.push({query: this.filters}).catch(() => {});
      this.searchPlaces(1);
    },
    searchPlaces(page) {
      this.searchError = false;
      this.searchErrorText = '';
      this.scrollToSearchResults();
      const params = {page: page || 1, ...this.filters};
      return this.$store
        .dispatch('searchOverseasPlacesAction', params)
        .then(res => {
          if (params.q && res.objects.length === 1) {
            this.$router.replace(getPlaceDetailRoute(res.objects[0].id, res.objects[0].pinyin));
          }
          this.showResults = true;
        })
        .catch(error => {
          this.searchError = true;
          this.searchErrorText = error && error.response && error.response.data ? error.response.data.error : '';
        });
    },
    scrollToSearchResults() {
      this.$scrollTo(this.scrollToElement, {offset: this.scrollOffset, cancelable: false});
    },
    onSwitchPage(page) {
      this.saveToRouteQuery({page});
      this.searchPlaces(page);
    },
    saveToRouteQuery(filters) {
      let query = {...this.$route.query, ...filters};
      this.$router.push({query: query}).catch(() => {});
    },
    getPopularCommunityCountry(id, name) {
      return {id: id, label: name, route: getPlaceDetailRoute(id, name)};
    },
    mapGetTextDisplay(item) {
      return `Estimated Population<br>${item.pinyin}<br>${item.est_population.toLocaleString('en')}`;
    },
  },
  name: 'SearchOverseasBasePage',
  components: {
    SearchOverseasResultItem,
    BasePagination,
    BasePaginationMeta,
    SearchOverseasForm,
    McrButton,
    SearchError,
    DataMap,
  },
};
</script>

<style scoped lang="scss">
@import '~@common/style/search-tool-list.scss';

.search-overseas-page {
  min-height: 700px;
  .meta-info-item {
    margin-top: 24px;

    .name:not(:last-child)::after {
      content: ', ';
    }
  }

  .data-map::v-deep .map {
    height: 650px;

    @media only screen and (max-width: $breakpoint-desktop) and (min-width: 1150px) {
      height: 600px;
    }
    @media only screen and (max-width: 1150px) and (min-width: 1050px) {
      height: 500px;
    }
    @media only screen and (max-width: 1050px) and (min-width: $breakpoint-tablet) {
      height: 400px;
    }
    @media only screen and (max-width: $breakpoint-tablet) and (min-width: 830px) {
      height: 600px;
    }
    @media only screen and (max-width: 830px) and (min-width: $breakpoint-phablet) {
      height: 500px;
    }
    @media only screen and (max-width: $breakpoint-phablet) and (min-width: 570px) {
      height: 440px;
    }
    @media only screen and (max-width: 570px) and (min-width: 470px) {
      height: 360px;
    }
    @media only screen and (max-width: 470px) {
      height: 300px;
    }
  }
}
</style>
